import React, { useState, useContext, useCallback } from "react"
import { useTranslation } from "react-i18next"
import Dots from "@/components/Chart/Dots"
import { useBasicStyle } from "@/theme"
import ContextStore from "@/contextStore"
import { SET_DEFENDANT } from "@/reducers/stickyCard"
import { trackCustomEvent } from "@/utils/ga"
import { getDefendantName } from "@/utils/name"

const DefendantDots = ({ selectedDefendants, context, showCount = true }) => {
  const { i18n, t } = useTranslation()
  const classes = useBasicStyle()
  const {
    stickyCard: { dispatch },
  } = useContext(ContextStore)

  const [width, setWidth] = useState(0)
  const containerRef = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width)
    }
  })

  const passCurrentDefendant = e => {
    if (e === null) {
      dispatch({ type: null })
      return
    }

    dispatch({
      type: SET_DEFENDANT,
      selectedDefendant: {
        ...e.d,
        id: e.d.id,
        // person: e.d,
        context: e.context,
      },
      i18n: i18n,
    })

    trackCustomEvent("Click", {
      event_category: "Dot",
      event_label: `${e.d.id} - ${getDefendantName({
        defendant: {
          ...e.d,
        },
        i18n,
      })}`,
    })
  }

  return (
    <div ref={containerRef}>
      {width ? (
        <>
          {showCount ? (
            <div className={classes.defendantCount}>
              {t("common.defendant_count", {
                count: selectedDefendants.length,
              })}
            </div>
          ) : (
            <></>
          )}
          <div className={classes.dotsWrapper}>
            <Dots
              width={width}
              data={selectedDefendants}
              context={context}
              passCurrent={passCurrentDefendant}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default DefendantDots
