import React, { useContext } from "react"
import styled from "styled-components"
import Link from "@/components/Link"
import CentreContainer from "@/components/Container"
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import ContextStore from "@/contextStore"
import { BasicInfo } from "@/components/Profile"
import { useTranslation } from "react-i18next"

const CloseButton = styled.div`
  ${({ theme, onCloseBtnClick, isFooterShowing }) => `
    padding: ${theme.spacing(1)}px 0 0 0;
    cursor: pointer;
    visible: ${onCloseBtnClick && !isFooterShowing};
  `}
`

const Wrapper = styled.div`
  display: flex;
  span {
    flex-grow: 1;
  }
`

const StyledStickyBottomCard = styled.div`
  ${({ theme, backgroundColor }) => `
    position: sticky;
    bottom: 0;
    background: ${backgroundColor || theme.palette.background.paper};
    border-top: ${theme.palette.primary.main} 1px solid;
    z-index: 1000;
    width: 100%;
  `}
`

const CardContent = ({ to, children, onCloseBtnClick }) => {
  return (
    <CentreContainer>
      <Wrapper>
        {to.obj ? (
          <Link to={to}>
            <div className="main">{children}</div>
          </Link>
        ) : null}
        <CloseButton onClick={e => onCloseBtnClick(e)}>
          <CloseRoundedIcon fontSize="large" />
        </CloseButton>
      </Wrapper>
    </CentreContainer>
  )
}

export const StickyCard = ({ backgroundColor }) => {
  const {
    footer: {
      state: { isShowing: isFooterShowing },
    },
    stickyCard: {
      state: { selectedDefendant, shown },
      dispatch,
    },
  } = useContext(ContextStore)

  const { i18n } = useTranslation()

  return (
    <StyledStickyBottomCard
      backgroundColor={backgroundColor}
      style={{ visibility: shown ? "visible" : "hidden" }}
      hidden={!shown && isFooterShowing}
    >
      <CardContent
        to={{
          page: "template-defendant",
          lang: i18n.language,
          obj: selectedDefendant,
        }}
        onCloseBtnClick={() => dispatch({ type: null })}
        isFooterShowing={isFooterShowing}
      >
        <BasicInfo
          defendant={selectedDefendant.person}
          status={selectedDefendant.status}
          progress={selectedDefendant.progress}
          context={selectedDefendant.context}
        />
      </CardContent>
    </StyledStickyBottomCard>
  )
}
