import React, { useContext } from "react"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/core/styles"
import Fade from "@material-ui/core/Fade"
import ContextStore from "@/contextStore"

const config = {
  radius: 14,
  strokeWidth: 3,
  itemMargin: 10,
}

const useStyles = makeStyles(theme => ({
  default: {},
  active: {
    stroke: props =>
      props.isWhiteBackground
        ? theme.palette.secondary.main
        : theme.palette.background.paper,
  },
}))

function Dots({
  data,
  passCurrent,
  context = "status",
  width,
  isWhiteBackground = true,
}) {
  const {
    stickyCard: {
      state: { selectedDefendant: activeDot },
    },
  } = useContext(ContextStore)
  const theme = useTheme()
  const viewport7 = useMediaQuery(theme.breakpoints.up("viewport7"))

  const numberofItems = data.length
  const itemsPerRow = Math.floor(
    (width + config.itemMargin) /
      ((config.radius + config.strokeWidth) * 2 + config.itemMargin)
  )
  const numberOfRows = Math.ceil(numberofItems / itemsPerRow)
  const lastRowItems = numberofItems % itemsPerRow
  // stack rect for each data value
  const classes = useStyles({
    theme,
    isWhiteBackground,
  })

  const handleClick = d => {
    passCurrent({ d, context })
  }

  const handleMouseOver = d => {
    // Chrome onClick event will trigger onMouseOver event as well
    if (viewport7) {
      if (!activeDot || (activeDot && activeDot.id !== d.id)) {
        passCurrent({ d, context })
      }
    }
  }

  const onClickBlankArea = e => {
    if (e.target.tagName !== "circle") {
      passCurrent(null)
    }
  }

  return (
    <Fade in={true} timeout={1000}>
      <div
        style={{
          display: "grid",
          marginTop: theme.spacing(1),
          gridTemplateRows: `repeat(${numberOfRows || 1}, 1fr)`,
          gridTemplateColumns: `repeat(${itemsPerRow || 1}, 1fr)`,
          gap: "5px",
        }}
        onClick={e => onClickBlankArea(e)}
      >
        {data.map((d, i) => {
          let x, y
          if (
            i > numberOfRows * lastRowItems - 1 &&
            numberOfRows * lastRowItems - 1 >= 0
          ) {
            x = Math.floor((i - lastRowItems) / (numberOfRows - 1))
          } else {
            x = Math.floor(i / numberOfRows)
          }

          if (
            i > numberOfRows * lastRowItems - 1 &&
            numberOfRows * lastRowItems - 1 >= 0
          ) {
            y = (i - numberOfRows * lastRowItems) % (numberOfRows - 1)
          } else {
            y = i % numberOfRows
          }
          return (
            <svg
              key={`${i}=${d.id || d.person.id}`}
              onClick={e => {
                e.stopPropagation()
                handleClick(d)
              }}
              style={{
                height: (config.radius + config.strokeWidth) * 2,
                width: (config.radius + config.strokeWidth) * 2,
                cursor: "pointer",
                gridRow: `${y + 1} / span 1`,
                gridColumn: `${x + 1} / span 1`,
              }}
            >
              <circle
                onMouseOver={() => handleMouseOver(d)}
                cx={config.radius + config.strokeWidth}
                cy={config.radius + config.strokeWidth}
                r={config.radius}
                className={
                  activeDot &&
                  activeDot.context === context &&
                  activeDot.id === d.id
                    ? classes.active
                    : classes.default
                }
                strokeWidth={config.strokeWidth}
                fill={d[context] && d[context].fill}
              />
            </svg>
          )
        })}
      </div>
    </Fade>
  )
}

export default Dots
