import React from "react"
import styled from "styled-components"
import CentreContainer from "@/components/Container"
import { useTranslation } from "react-i18next"

const OuterWrapper = styled.div`
  ${({ theme }) => `
  width: 100%;
  background-color: white;
  border-top: 1px white solid;
  padding-top: ${theme.spacing(1)}px;

  .remarks {
    padding-top: ${theme.spacing(1)}px;
    padding-bottom: ${theme.spacing(1)}px;
    font-weight: 700;
    font-size: 0.75rem;
  }
`}
`
const InnerWrapper = styled.div`
  ${({ theme, columns, handleClick }) => `
  display: grid;
  grid-template-columns: repeat(${columns}, minmax(0, 1fr));
  grid-gap: ${theme.spacing(0.5)}px;
  cursor: ${handleClick ? "pointer" : "default"};

  .legend {
      text-align: center;
      padding-bottom: ${theme.spacing(1)}px;
      opacity: 50%;
  }

  .legend:hover {
    transition: 0.5s;
    opacity: 100%;
  }

  .legend-highlight {
    border-bottom: 1px ${theme.palette.primary.main} solid;
    opacity: 100%;
  }
  
  .legend__title {
      font-size: 0.65rem;
      font-weight: 700;
  }

  ${theme.breakpoints.up("viewport7")} {
    .legend__title {
        font-size: 0.85rem;
    }
  }

  `}
`
const radius = 8

const LegendItems = {
  incidents: [
    { id: 4, title: "拘捕令", color: "#a90100" },
    { id: 6, title: "服畢刑期", color: "#ef664c" },
    { id: 3, title: "服刑中", color: "#570606" },
    { id: 2, title: "還柙", color: "#0c2c96" },
    { id: 1, title: "保釋", color: "#94bbea" },
    { id: 5, title: "當庭釋放", color: "#5dc9c4" },
    { id: 0, title: "待更新", color: "#666666" },
  ],
  charge: [
    { id: 1, title: "司法程序進行中", color: "#0c2c96" },
    { id: 2, title: "結案", color: "#94bbea" },
    { id: 3, title: "上訴", color: "#f6d973" },
    { id: 99, title: "待更新", color: "#666666" },
  ],
}

const Legend = React.memo(function Legend({
  type,
  items,
  activeId,
  handleClick,
}) {
  items = items || LegendItems[type]

  const { t } = useTranslation()

  return (
    <OuterWrapper>
      <CentreContainer>
        <InnerWrapper columns={items.length} handleClick={!!handleClick}>
          {items.map((item, i) => {
            const { title, color } = item
            return (
              <div
                key={title}
                className={
                  activeId === item.id ? "legend legend-highlight" : "legend"
                }
                onClick={() => handleClick && handleClick(i, items)}
              >
                <svg
                  style={{
                    height: radius * 2,
                    width: radius * 2,
                  }}
                >
                  <circle cx={radius} cy={radius} r={radius} fill={color} />
                </svg>
                <div className="legend__title">{title}</div>
              </div>
            )
          })}
        </InnerWrapper>
        <div className="remarks">{t(`defendant.status_5_remarks`)}</div>
      </CentreContainer>
    </OuterWrapper>
  )
})

export default Legend
